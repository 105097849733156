"use client";

import {useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import NavLink from "./NavLink";
import NavLinkLogo from "./NavLinkLogo";
import {usePathname} from "next/navigation";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const pathname = usePathname();

  // Lock the body scroll when menu is open and prevent horizontal scroll
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMenuOpen]);

  const handleLinkClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <header className={`absolute z-50 top-0 left-0 w-full transition-all duration-300 overflow-x-hidden ${isMenuOpen ? "bg-black bg-opacity-90" : "bg-transparent"}`}>
      <div className="container mx-auto">
        <nav className="flex items-center justify-between py-4">
          {/* Logo */}
          <NavLinkLogo />

          {/* Mobile Menu Toggle */}
          <button title="Navigation menu" className="lg:hidden text-white focus:outline-none z-50" aria-controls="navbarNavDropdown" aria-expanded={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="h-6 w-6" />
          </button>

          {/* Fullscreen Overlay Menu */}
          <div
            className={`fixed inset-0 bg-black bg-opacity-80 transition-transform duration-300 ease-in-out lg:static lg:bg-transparent lg:flex lg:items-center lg:space-x-6 ${
              isMenuOpen ? "translate-x-0" : "translate-x-full lg:translate-x-0"
            }`}
            id="navbarNavDropdown">
            <ul className={`flex flex-col lg:flex-row items-center lg:space-x-6 space-y-4 lg:space-y-0 text-2xl font-tungsten-semibold mt-16 lg:mt-0 ${isMenuOpen ? "pb-10" : ""} lg:pb-0`}>
              <li>
                <NavLink href="/exchange" label="Exchange" isActive={pathname === "/exchange"} onClick={handleLinkClick} />
              </li>
              {/* <li>
                <NavLink href="/partners" label="Partners" isActive={pathname === "/partners"} onClick={handleLinkClick} />
              </li> */}
              <li>
                <NavLink href="/units" label="Units" isActive={pathname === "/units"} onClick={handleLinkClick} />
              </li>
              <li>
                <NavLink href="/exchange-listings" label="Listings" isActive={pathname === "/exchange-listings"} onClick={handleLinkClick} />
              </li>

              <li>
                <NavLink href="/news-and-blog" label="News & Blog" isActive={pathname === "/news-and-blog"} onClick={handleLinkClick} />
              </li>
              <li>
                <NavLink href="/for-investors" label="For Investors" isActive={pathname === "/for-investors"} onClick={handleLinkClick} />
              </li>
              {/* <li>
                <NavLink href="/for-kids" label="For Kids" isActive={pathname === "/for-kids"} onClick={handleLinkClick} />
              </li> */}
              <li>
                <NavLink href="/for-companies" label="For Companies" isActive={pathname === "/for-companies"} onClick={handleLinkClick} />
              </li>
              {/* <li>
                <NavLink href="/about" label="About" isActive={pathname === "/about"} onClick={handleLinkClick} />
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
