// File: VideoPlayerClient.tsx (Client Component)
"use client"; // ensures useEffect and browser APIs are available

import React, {useEffect, useRef} from "react";

interface VideoSource {
  src: string;
  type: string;
}

interface VideoPlayerClientProps {
  videoSources: VideoSource[];
  poster: string;
  isBot: boolean;
}

export default function VideoPlayerClient({videoSources, poster, isBot}: VideoPlayerClientProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    // 1-second delay before playback, but only if NOT a bot
    if (!isBot && videoRef.current) {
      const timer = setTimeout(() => {
        videoRef.current?.play().catch((err) => console.warn("Video playback failed:", err));
      }, 1000);

      // Cleanup the timer on unmount
      return () => clearTimeout(timer);
    }
  }, [isBot]);

  return (
    <video ref={videoRef} loop muted playsInline preload="none" poster={poster} className="w-full h-full object-cover">
      {!isBot && videoSources.map((source, index) => <source key={index} src={source.src} type={source.type} />)}
      Your browser does not support the video tag.
    </video>
  );
}
