"use client";
import React, {useEffect, useState, useMemo} from "react";

export const ProgressBarMainPage: React.FC = () => {
  const [current, setCurrent] = useState<number>(700000);
  const [max, setMax] = useState<number>(1000000);
  // const [remaining, setRemaining] = useState<number>(100);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/mozgunits");
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setCurrent(data.totalUnits);
        // setRemaining(data.remainingUnits);
        setMax(data.mozgUnits);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  const percentage = useMemo(() => {
    if (max === 0) return 0;
    return (current / max) * 100;
  }, [current, max]);

  const currentFormatted = current.toLocaleString();
  // const maxFormatted = max.toLocaleString();
  // const remainingFormatted = remaining.toLocaleString();
  return (
    <div className="w-full max-w-lg mx-auto  rounded shadow-lg p-6 sm:p-8 ">
      {/* Title / Heading */}
      <h2 className="text-3xl xs:text-4xl sm:text-5xl text-shadow-lg  text-white uppercase mb-4  font-tungsten-semibold font-semibold">
        Active Phase: Epoch “<span className="text-gold text-shadow-lg">Inception</span>”
      </h2>

      {/* Progress Bar Container */}
      <div className="relative bg-gray-200 rounded h-8 sm:h-10 overflow-hidden shadow-inner">
        {/* Filled Portion (with gradient) */}
        <div className="flex items-center justify-center h-8 sm:h-10 transition-all duration-300 bg-gradient-to-r from-amber-400 via-yellow-400 to-yellow-500" style={{width: `${percentage}%`}}>
          <span className="text-black font-semibold text-xs sm:text-sm px-1 truncate">{percentage.toFixed(1)}%</span>
        </div>
      </div>

      <div className="mt-4 text-white">
        <div className="flex flex-row justify-between text-xl xs:text-2xl sm:text-3xl uppercase font-tungsten-semibold text-shadow-lg">
          <span>
            Claimed MOZG Units: <span className="font-bold">{currentFormatted}</span> / 1M
          </span>
          {/* <span>{percentage.toFixed(1)}%</span> */}
        </div>
      </div>
    </div>
  );
};
