import Link from "next/link";
import Image from "next/image";

interface NavLinkLogoProps {
  className?: string;
}

const NavLinkLogo: React.FC<NavLinkLogoProps> = ({className}) => {
  return (
    <Link href="/" className={`group ${className || ""}`}>
      <div>
        <div className="logo-mozg">
          <Image className="inline-block mr-2 transform transition duration-300 hover:scale-110 hover:opacity-80" src="/mozgii_logo2.png" alt="logo" width={128} height={128} />
        </div>
      </div>
    </Link>
  );
};

export default NavLinkLogo;
