import Link from "next/link";

interface NavLinkProps {
  href: string;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
}

const NavLink = ({href, label, isActive, onClick}: NavLinkProps) => {
  return (
    <Link href={href} onClick={onClick} className={`transition-colors text-white hover:text-gold hover:bg-transparent uppercase duration-200 px-3 py-2 text-2xl`} aria-current={isActive ? "page" : undefined}>
      {label}
    </Link>
  );
};

export default NavLink;
