import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/AOSProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/components/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/main.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"/fonts/TungstenSemibold_normal_normal.woff2\",\"weight\":\"600\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-tungsten-semibold\"}],\"variableName\":\"tungstenSemibold\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/fonts.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"/fonts/HelveticaNeueLTProMd.woff2\",\"weight\":\"400\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-helvetica-neue-md\"}],\"variableName\":\"helveticaNeueLTProMd\"}");
