"use client";

import {useCallback} from "react";
import {sendGAEvent} from "@next/third-parties/google";

interface SignUpButtonProps {
  buttonName: string;
}

export default function SignUpButton({buttonName}: SignUpButtonProps) {
  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // Safely call Yandex Metrica only if it's defined
    if (typeof window !== "undefined" && typeof window.ym === "function") {
      window.ym("94764572", "reachGoal", "sign_up_click");
    }

    sendGAEvent("event", "sign_up_click");

    // if (typeof window !== "undefined" && typeof window.gtag === "function") {
    //   console.log("gtag event: exchange_sign_up");
    //   window.gtag("event", "exchange_sign_up", {
    //     sign_up_click: "1"
    //   });
    // }

    // Open the target URL in a new tab
    window.open("https://exchange.mozgii.com/", "_blank");
  }, []);

  return (
    <button
      onClick={handleClick}
      className="inline-block px-6 py-3 
                 bg-gold text-black font-bold text-xl 
                 sm:text-2xl md:text-3xl font-tungsten-semibold 
                 uppercase hover:bg-black hover:text-gold 
                 transition-all duration-300">
      {buttonName}
    </button>
  );
}
